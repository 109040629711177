export const contractAddresses = {
  market: {
    56: "",
    97: "0xa58b3E9E42078E1B8A9d709aaAB3aa4aAe429890",
  },
  // Main ERC20
  dvdx: {
    56: "",
    97: "0xc9d59bA1AcC61bb8a16a878Da7f40D538F042701",
  },
  // Asset ERC20
  usdx: {
    56: "",
    97: "0x260f6a42C14Ce903948dB23819FCC1dF67Ba6026",
  },
  // Derived USDC
  usdc: {
    56: "",
    97: "0x8c9C613DA9adBe462F71E273C8284610E6F57247",
  },
  // Fee Pool
  feePool: {
    56: "",
    97: "0x5f3c506Fa35B88dB451B882616303EE842edaC2e",
  },
  // Depot
  depot: {
    56: "",
    97: "0x7B0A1e204A4292b36A1E125a5Fc69F0281fc6124",
  },
  exchangeRate: {
    56: "",
    97: "0xf34Cf5e99398484E4C76bb7c6fb777feCBF80d69",
  },
  btc: {
    56: "",
    97: "0xE6f67b78b3904146Cc2F749F760dD8e9b63E89eA",
  },
  eth: {
    56: "",
    97: "0x1cab3EA139d4caFA9Ac2cE3e2F22837053f27CC8",
  },
  bnb: {
    56: "",
    97: "0x1377522291799Ed8BE9Ea4B9479615BEc68E959E",
  },
};
