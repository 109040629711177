import { GraphQLClient } from 'graphql-request';

import {
  FETCH_QUESTIONS,
  FETCH_TRADES_BY_QUESTION,
  FETCH_QUESTION_BY_ID
} from './queries/binary-market';

export const GRAPH_API = {
  56: "https://api.thegraph.com/subgraphs/name/mk-derived/derivedmarket",
  97: "https://api.thegraph.com/subgraphs/name/mk-derived/derivedmarket",
};

export const fetchQuestions = async (chainId = 97, status = "READY") => {
  const client = new GraphQLClient(GRAPH_API[chainId]);

  let limit = 10, page = 0, data = [];
  let res = await client.request(FETCH_QUESTIONS(limit, page, status));

  while (res.questions.length > 0) {
    data = data.concat(res.questions);
    page++;

    res = await client.request(FETCH_QUESTIONS(limit, page, status));
  }

  return data;
};

export const fetchTradesByQuestion = async (chainId = 97, id) => {
  const client = new GraphQLClient(GRAPH_API[chainId]);

  let limit = 10, page = 0, data = [];
  let res = await client.request(FETCH_TRADES_BY_QUESTION(id, limit, page));

  while (res.trades.length > 0) {
    data = data.concat(res.trades);
    page++;

    res = await client.request(FETCH_TRADES_BY_QUESTION(id, limit, page));
  }

  return data;
};

export const fetchQuestionDetail = async (chainId = 97, id) => {
  const client = new GraphQLClient(GRAPH_API[chainId]);
  const res = await client.request(FETCH_QUESTION_BY_ID(id));
  if (res.questions && res.questions.length > 0) {
    return res.questions[0];
  }

  return null;
};
