import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Box from '@mui/material/Box';

const TabPagination = ({
  count = 1,
  shape = "",
  page,
  handlePage,
  numPerPage,
  handleCount
}) => {
  return (
    <Box sx={{ display: "inline-flex", m: 2, width: "100%", justifyContent: "center" }}>
      <Pagination
        onChange={(e, page) => handlePage(page)}
        count={count}
        page={page}
        shape={shape}
        color="secondary"
        renderItem={(item) => (
          <PaginationItem
            color="secondary"
            {...item}
          />
        )}
      />
    </Box>
  );
}

export default TabPagination;