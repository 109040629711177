import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import ThemeProvider from "./theme";

import App from "./App";
import Providers from "./Providers";

ReactDOM.render(
  <ThemeProvider>
    <Providers>
      <Router>
        <App />
      </Router>
    </Providers>
  </ThemeProvider>,
  document.getElementById("root")
);
