import { useMemo, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CircularProgress from "@mui/material/CircularProgress";

import Singlebinaryblock from "./Singlebinaryblock";
import { useMarket } from "../../context/market";
import Pagination from "../ui/Pagination";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

const Ongoingtabs = ({ category, sortBy }) => {
  const {
    liveQuestions,
    resolvedQuestions,
    loading: loadingMarketData,
  } = useMarket();
  const [onPage, setOnPage] = useState(1);
  const [exPage, setExPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(12);

  const filterQuestion = (questions) =>
    questions
      .filter((question) => {
        if (category === "all") return true;
        return question.category === category;
      })
      .sort((quzA, quzB) => {
        if (sortBy === "asc") return +quzA.resolveTime - +quzB.resolveTime;
        return +quzB.resolveTime - +quzA.resolveTime;
      });

  const ongoingQuestions = useMemo(
    () => filterQuestion(liveQuestions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveQuestions, category, sortBy]
  );
    
  const expiredQuestions = useMemo(
    () => filterQuestion([...resolvedQuestions]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolvedQuestions, category, sortBy]
  );

  const handleOngoingPage = (page) => {
    setOnPage(page);
  }

  const handleExpriedPage = (page) => {
    setExPage(page);
  }

  const handleCount = (e) => {
    setNumPerPage(e.target.value);
    setOnPage(1);
    setExPage(1);
  }

  return (
    <Tabs style={{ padding: "10px 0px" }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <TabList style={{ width: "100%" }}>
            <Tab>OnGoing</Tab>
            <Tab>Expired</Tab>
          </TabList>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{display: "inline-flex", width: "100%", height: "100%", p: 2, justifyContent: "center", alignItems: "center" }}>
            <InputLabel sx={{ display: "flex", alignItems: "center", mr: 2}}>Items per page</InputLabel>
            <FormControl variant="standard" sx={{ minWidth: 50 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={numPerPage}
                onChange={handleCount}
                label="Items per page"
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={90}>90</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <TabPanel>
        {loadingMarketData ? (
          <div className="justify-center flex">
            <CircularProgress />
          </div>
        ) : (
          <>
            {ongoingQuestions && ongoingQuestions.length > 0 ? (
              <>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                  {ongoingQuestions.slice(numPerPage * (onPage - 1), numPerPage * onPage).map((question) => (
                    <Singlebinaryblock key={question.id} {...question} />
                  ))}
                </div>
                <Pagination
                  count={Math.ceil(ongoingQuestions.length / numPerPage)}
                  variant="outlined"
                  shape="rounded"
                  page={onPage}
                  handlePage={handleOngoingPage}
                  numPerPage={numPerPage}
                  handleCount={handleCount}
                />
              </>
            ) : (
              <h3 className="text-center">No live questions</h3>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel>
        {loadingMarketData ? (
          <div className="justify-center flex">
            <CircularProgress />
          </div>
        ) : (
          <>
            {expiredQuestions && expiredQuestions.length > 0 ? (
              <>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                  {expiredQuestions.slice(numPerPage * (exPage - 1), numPerPage * exPage).map((question) => (
                    <Singlebinaryblock key={question.id} {...question} />
                  ))}
                </div>
                <Pagination
                  count={Math.ceil(expiredQuestions.length / numPerPage)}
                  variant="outlined"
                  shape="rounded"
                  page={exPage}
                  handlePage={handleExpriedPage}
                  numPerPage={numPerPage}
                  handleCount={handleCount}
                />
              </>
            ) : (
              <h3 className="text-center">No expired questions</h3>
            )}
          </>
        )}
      </TabPanel>
    </Tabs>
  );
};

export default Ongoingtabs;
