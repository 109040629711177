import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#475569",
    },
    secondary: {
      main: '#273a46',
    },
    text: {
      primary: "#ffffff",
      secondary: "#94a3b8"
    },
    background: {
      default: "ffffff",
      paper: "#18252D"
    }
  },
});

export default function Palette({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}