import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "bignumber.js";

import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  Grid
} from "@mui/material";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import Chart from "../../partials/dashboard/Chart";
import Buysell from "../../partials/binary/Buysell";
import ExpiredTab from "../../partials/binary/ExpiredTab";
import Marketposition from "../../partials/binary/Marketposition";
import TradeTable from "../../partials/trade/TradeTable";
// import {
//   fetchQuestionDetail,
//   fetchTradesByQuestion,
// } from "../../services/market";
import {
  fetchQuestionDetail,
  fetchTradesByQuestion,
} from "../../services/binary-market";

import { toShort18 } from "../../utils/Contract";
import { toFriendlyTimeFormat, toShortAddress } from "../../utils/Utils";
import { getJsonIpfs } from "../../utils/Ipfs";
import { useChain } from "../../context/chain";
import { useFinance } from "../../context/finance";
import { useMarket } from "../../context/market";

const BinaryInside = () => {
  const { questionId } = useParams();
  const { MarketContract, USDCContract } = useChain();
  const { fetchBalances } = useFinance();
  const { chainId, account } = useWeb3React();
  const { resolver } = useMarket();

  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [question, setQuestion] = useState({});
  const [USDCBalance, setUSDCBalance] = useState(new BigNumber(0));
  const [trades, setTrades] = useState([]);
  const [prices, setPrices] = useState([]);
  const [balances, setBalances] = useState({
    0: new BigNumber(0),
    1: new BigNumber(0),
  });

  const scanlink = useMemo(() => {
    if (chainId === "56") return `https://bscscan.com`;

    return `https://testnet.bscscan.com`;
  }, [chainId]);

  const handleFetchDetails = async () => {
    setLoading(true);
    setLoadingPrice(true);

    const data = await fetchQuestionDetail(chainId, questionId);
    if (!data) {
      console.error("Fetching question error: ", questionId);
    } else {
      const tradeData = await fetchTradesByQuestion(chainId, data.id);
      setTrades(
        (tradeData || []).map((trade, index) => ({
          ...trade,
          prevLong:
            index === 0 ? "500000000000000000" : tradeData[index - 1].slot1,
          prevShort:
            index === 0 ? "500000000000000000" : tradeData[index - 1].slot2,
        }))
      );

      setPrices([
        {
          // index: +data.createTime * 1000,
          index: 0,
          long: 0.5,
          short: 0.5,
        },
        ...tradeData
          .sort(
            (tradeA, tradeB) =>
              parseInt(tradeA.timestamp, 10) - parseInt(tradeB.timestamp, 10)
          )
          .map((trade, index) => ({
            // index: +trade.timestamp * 1000,
            index: index + 1,
            long: parseFloat(toShort18(trade.slot1).toFixed(2)),
            short: parseFloat(toShort18(trade.slot2).toFixed(2)),
          })),
      ]);

      const details = await getJsonIpfs(data.meta);

      const long = toShort18(data.slot1);
      const short = toShort18(data.slot2);
      const liquidity = await MarketContract.getLiquidityVolume(questionId);
      const trade = await MarketContract.getTradeVolume(questionId);
      // const market = await MarketContract.markets(questionId);

      setQuestion({
        ...data,
        details,
        resolveTime: toFriendlyTimeFormat(+data.resolveTime || 0),
        long: long.toFixed(2),
        short: short.toFixed(2),
        fLong: long,
        fShort: short,
        liquidity: toShort18(liquidity.toString()).toFixed(2),
        trade: toShort18(trade.toString()).toFixed(2),
      });
    }

    setLoading(false);
    setLoadingPrice(false);
  };

  const handleRefreshPrice = async () => {
    setLoadingPrice(true);
    await handleFetchDetails();
    await fetchBalances();
    setLoadingPrice(false);
  };

  const handleUpdatePrice = async (
    tx,
    status,
    timestamp,
    tokensAmount,
    sharesAmount,
    slotIndex
  ) => {
    setLoading(true);
    setLoadingPrice(true);

    const [data, liquidity, trade] = await Promise.all([
      MarketContract.getPrices(questionId),
      MarketContract.getLiquidityVolume(questionId),
      MarketContract.getTradeVolume(questionId),
    ]);
    // const market = await MarketContract.markets(questionId);

    setQuestion((val) => ({
      ...val,
      long: toShort18(data[0].toString()).toFixed(2),
      short: toShort18(data[1].toString()).toFixed(2),
      fLong: toShort18(data[0].toString()),
      fShort: toShort18(data[1].toString()),
      liquidity: toShort18(liquidity.toString()).toFixed(2),
      trade: toShort18(trade.toString()).toFixed(2),
    }));

    setTrades((val) => [
      {
        tokensAmount,
        sharesAmount,
        status,
        timestamp,
        transaction: tx.hash,
        trader: tx.from,
        answer: slotIndex === 0 ? "LONG" : "SHORT",
        long: new BigNumber(data[0].toString()).toFixed(),
        short: new BigNumber(data[1].toString()).toFixed(),
        prevLong:
          val.length === 0 ? "500000000000000000" : val[val.length - 1].long,
        prevShort:
          val.length === 0 ? "500000000000000000" : val[val.length - 1].short,
      },
      ...val,
    ]);
    setPrices((val) => [
      ...val,
      {
        index: val.length,
        long: parseFloat(toShort18(data[0].toString()).toFixed(2)),
        short: parseFloat(toShort18(data[1].toString()).toFixed(2)),
      },
    ]);

    const longBalance = await MarketContract.balanceOf(
      account,
      +questionId * 2
    );
    const shortBalance = await MarketContract.balanceOf(
      account,
      +questionId * 2 + 1
    );

    setBalances({
      0: toShort18(longBalance.toString()),
      1: toShort18(shortBalance.toString()),
    });

    setLoadingPrice(false);
    setLoading(false);
  };

  useEffect(() => {
    questionId !== undefined &&
      questionId !== null &&
      MarketContract &&
      handleFetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, chainId, MarketContract]);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      setLoadingPrice(true);

      const longBalance = await MarketContract.balanceOf(
        account,
        +questionId * 2
      );
      const shortBalance = await MarketContract.balanceOf(
        account,
        +questionId * 2 + 1
      );

      const usdc = await USDCContract.balanceOf(account);

      setBalances({
        0: toShort18(longBalance.toString()),
        1: toShort18(shortBalance.toString()),
      });

      setUSDCBalance(toShort18(usdc.toString()));

      setLoadingPrice(false);
      setLoading(false);
    };

    if (
      questionId !== undefined &&
      questionId !== null &&
      account &&
      MarketContract &&
      USDCContract
    ) {
      initialize();
    } else {
      setBalances({
        0: new BigNumber(0),
        1: new BigNumber(0),
      });
      setUSDCBalance(new BigNumber(0));
    }
  }, [questionId, account, MarketContract, USDCContract]);

  return (
    <main>
      <div>
        <div className=" bg-secondary mx-10 rounded-lg">
          <Box sx={{ p: 5, pb: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                xl={6}
              >
                <div className="h-full flex items-center md:flex-row flex-col sm:mx-4">
                  {loading ? (
                    <Skeleton variant="text" width={450} height={80} />
                  ) : (
                    <p className="text-white text-2xl font-bold">
                      {question.details && question.details.title}
                    </p>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                xl={2}
              >
                <div className="sm:w-40 h-10 sm:h-20 flex sm:bg-mutedtext items-center justify-center sm:mx-4 sm:my-2 p-4 rounded-lg hover:bg-headings">
                  <div className="flex sm:flex-col w-full justify-between">
                    <div className="flex sm:mb-4 justify-center">
                      <p className="text-gray-100 font-heading text-sm subpixel-antialiased group-hover:text-secondary font-black">
                        Market Ends On
                      </p>
                    </div>
                    <div className="flex justify-center">
                      {loading ? (
                        <Skeleton width={100} height={30} />
                      ) : (
                        <p className="text-headings sm:text-black text-base font-body font-black whitespace-nowrap">
                          {question.resolveTime}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                xl={2}
              >
                <div className="sm:w-40 h-10 sm:h-20 flex sm:bg-mutedtext items-center justify-center sm:mx-4 sm:my-2 p-4 rounded-lg hover:bg-headings">
                  <div className="flex sm:flex-col w-full justify-between">
                    <div className="flex sm:mb-4 justify-center">
                      <p className="text-gray-100 font-heading text-sm subpixel-antialiased group-hover:text-secondary font-black">
                        Trade volume
                      </p>
                    </div>
                    <div className="flex justify-center">
                      {loading ? (
                        <Skeleton width={100} height={30} />
                      ) : (
                        <p className="text-headings sm:text-black text-base font-body font-black whitespace-nowrap">
                          <AttachMoneyOutlinedIcon />
                          {question.trade}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                xl={2}
              >
                <div className="sm:w-40 h-10 sm:h-20 flex sm:bg-mutedtext items-center justify-center sm:mx-4 sm:my-2 p-4 rounded-lg hover:bg-headings">
                  <div className="flex sm:flex-col w-full justify-between">
                    <div className="flex sm:mb-4 justify-center">
                      <p className="text-gray-100 font-heading text-sm subpixel-antialiased group-hover:text-secondary font-black">
                        Liquidity Volume
                      </p>
                    </div>
                    <div className="flex justify-center">
                      {loading ? (
                        <Skeleton width={100} height={30} />
                      ) : (
                        <p className="text-headings sm:text-black text-base font-body font-black whitespace-nowrap">
                          <AttachMoneyOutlinedIcon />
                          {question.liquidity}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>          
          <Box sx={{ p: 5, pt: 0}}>
            <div className="flex sm:flex-row flex-col pt-5 text-center border-t-2 border-solid sm:border-none">
              <Box sx={{ px: 2}}>
                <div className="flex sm:justify-center justify-between">
                  <p className="text-white text-sm mr-2">Developed By:{"  "}</p>
                  {loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <a
                      href={`${scanlink}/address/${resolver}`}
                      target="blank"
                      className="text-sm text-headings"
                    >
                      {resolver && toShortAddress(resolver, 6)}
                    </a>
                  )}
                </div>
              </Box>
              &nbsp;&nbsp;&nbsp;
              <Box sx={{ px: 2}}>
                <div className="flex sm:justify-center justify-between">
                  <p className="text-white text-sm mr-2">Resolver:{"  "}</p>
                  {loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <a
                      href={`${scanlink}/address/${resolver}`}
                      target="blank"
                      className="text-sm text-headings"
                    >
                      {resolver && toShortAddress(resolver, 6)}
                    </a>
                  )}
                </div>                
              </Box>
            </div>
          </Box>
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto bg-primary">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-12 transition-all">
          <Chart prices={prices} />
          {question.status === "END" ? (
            <ExpiredTab {...question} balances={balances} />
          ) : (
            <Buysell
              {...question}
              loading={loadingPrice}
              balances={balances}
              USDCBalance={USDCBalance}
              onRefreshPrice={handleRefreshPrice}
              onUpdatePrice={handleUpdatePrice}
            />
          )}
        </div>
      </div>
      <p className="text-white text-2xl font-bold mx-8">Market Positions</p>
      <Marketposition
        {...question}
        balances={balances}
        loading={loading || loadingPrice}
      />
      <p className="text-white text-2xl font-bold underline decoration-secondary mx-8">
        About This Market
      </p>
      <div className="m-8">
        <p className="text-white font-base">
          {question.details && question.details.description}
        </p>
      </div>
      <div className="bg-secondary m-7 rounded-lg">
        <p className="text-white p-5">
          Resolution Source{" "}
          <span className="text-blue-500 mr-10 block">
            <a
              href={question.details && question.details.link}
              target="blank"
              className="truncate block"
            >
              {question.details && question.details.link}
            </a>
          </span>
        </p>
        <p className="text-white p-5">
          Resolver{" "}
          <span className="text-blue-500 mr-10">
            <a href={`${scanlink}/address/${resolver}`} target="blank">
              {resolver}
            </a>
          </span>
        </p>
      </div>

      <div className="flex bg-secondary p-3 flex-col m-7 rounded-lg">
        <h1 className=" text-white text-2xl font-bold">Recent Trading</h1>
        <TradeTable trades={trades} loading={loading || loadingPrice} />
      </div>
    </main>
  );
};

export default BinaryInside;
