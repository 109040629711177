import React from "react";

export default function Progressbar({ bgcolor, longRatio, shortRatio, height }) {
  const percentage = (longRatio * 100 / (longRatio + shortRatio)).toFixed(2);

  const Parentdiv = {
    height: height,
    width: "90%",
    backgroundColor: "red",
    borderRadius: 40,
    margin: 15,
    position: "relative",
  };

  const Childdiv = {
    height: "100%",
    width: `${percentage}%`,
    backgroundColor: bgcolor,
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    textAlign: "right",
    position: "absolute",
  };

  const progresstext = {
    color: "black",
    fontWeight: 900,
    fontSize: "12px",
    position: "absolute",
    marginTop: "-1px",
  };

  return (
    <div className="flex justify-center">
      <div style={Parentdiv}>
        <div style={Childdiv}>
          <span style={progresstext}></span>
        </div>
      </div>
    </div>
  );
}
