import React, { useState, useEffect, useContext, createContext } from "react";
import { useWeb3React } from "@web3-react/core";

import { fetchQuestions } from "../../services/binary-market";
import { useChain } from "../../context/chain";
import { generateUnixTimestamp } from "../../utils/Utils";

export const MarketContext = createContext({});

export const useMarket = () => useContext(MarketContext);

export const MarketProvider = ({ children }) => {
  const { MarketContract } = useChain();
  const [loading, setLoading] = useState(false);
  const [isMarketOwner, setMarketOwner] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [liveQuestions, setLiveQuestions] = useState([]);
  const [resolvedQuestions, setResolvedQuestions] = useState([]);

  const { chainId, account } = useWeb3React();

  const handleAddLiveQuestion = (question) => {
    setLiveQuestions((questions) => [
      ...questions,
      {
        id: question.questionId,
        questionId: question.questionId,
        title: question.title,
        createTime: generateUnixTimestamp(new Date()),
        resolveTime: question.resolveTime,
        status: "READY",
        answer: "NONE",
        slot1: "500000000000000000",
        slot2: "500000000000000000",
        lpVolume: question.funding,
        tradeVolume: 0,
        meta: question.meta,
        longvolume: question.funding,
        shortvolume: question.funding,
        paused: false,
      },
    ]);
  };

  const resolveQuestion = (questionId, answer) => {
    setResolvedQuestions((questions) =>
      questions.map((quz) =>
        quz.questionId === questionId
          ? { ...quz, answer: answer === 0 ? "YES" : "NO" }
          : { ...quz }
      )
    );
  };

  const pauseQuestion = (questionId) => {
    setLiveQuestions((questions) =>
      questions.map((quz) =>
        quz.questionId === questionId ? { ...quz, paused: true } : { ...quz }
      )
    );

    setResolvedQuestions((questions) =>
      questions.map((quz) =>
        quz.questionId === questionId ? { ...quz, paused: true } : { ...quz }
      )
    );
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);

      const ongoingQuzData = await fetchQuestions(chainId, "READY");
      setLiveQuestions(ongoingQuzData);

      const resolvedQuzData = await fetchQuestions(chainId, "END");
      setResolvedQuestions(resolvedQuzData);

      setLoading(false);
    };

    chainId && initialize();
  }, [chainId]);

  useEffect(() => {
    const fetchProxy = async () => {
      const owner = await MarketContract.owner();
      setMarketOwner(owner.toLowerCase() === account.toLowerCase());
      setResolver(owner);
    };

    MarketContract && account && fetchProxy();
  }, [MarketContract, account]);

  return (
    <MarketContext.Provider
      value={{
        loading,
        resolver,
        isMarketOwner,
        liveQuestions,
        resolvedQuestions,
        addLiveQuestion: handleAddLiveQuestion,
        resolveQuestion,
        pauseQuestion,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};
