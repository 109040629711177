import gql from "graphql-tag";

export const FETCH_QUESTIONS = (limit = 10, page = 0, status = null) => {
  const now = parseInt(new Date().getTime() / 1000);
  return gql(`
    query transactions {
      questions(
        first: ${limit}
        skip: ${page * limit}
        ${
          status
            ? `where: {
            ${
              status === "READY"
                ? `resolveTime_gt: ${now}`
                : `resolveTime_lte: ${now}`
            }
          }`
            : ""
        }
      )
      {
        id
        questionId
        title
        meta
        category
        createTime
        resolveTime
        initialLiquidity
        fee
        status
        answer
        slot1
        slot2
        paused
      }
    }
  `);
};

export const FETCH_QUESTION_BY_ID = (id) => {
  return gql(`
    query transactions {
      questions (
        where: {
          questionId: "${id}"
        }
      ) {
        id
        questionId
        title
        meta
        category
        createTime
        resolveTime
        initialLiquidity
        fee
        status
        answer
        slot1
        slot2
        paused
      }
    }
  `);
};

export const FETCH_TRADES_BY_QUESTION = (id, limit = 10, page = 0) => {
  return gql(`
    query transactions {
      trades (
        first: ${limit}
        skip: ${page * limit}
        orderBy: timestamp,
        orderDirection: desc
        where: {
          question: "${id}"
        }
      ) {
        id
        slot1
        slot2
        timestamp
        tokensAmount
        sharesAmount
        transaction
        trader
        status
        answer
      }
    }
  `);
};
