import React, { createContext, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#111",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  ['@media (max-width:510px)']: { // eslint-disable-line no-useless-computed-key
    width: '100%',
    height: '100%',
  }
};

const DisclaimerContext = createContext({});

export const useDisclaimer = () => useContext(DisclaimerContext);

export const DisclaimerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [errorDetail, setErrorDetail] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowError = (msg, rest) => {
    setShowError(true);
    setError(msg);
    setErrorDetail(rest);
  };

  const handleHideError = () => {
    setShowError(false);
    setError("");
    setErrorDetail("");
  };

  useEffect(() => {
    const initialize = () => {
      const showDisclaimer = localStorage.getItem("show-disclaimer");

      if (!showDisclaimer) {
        localStorage.setItem("show-disclaimer", true);
        setOpen(true);
      }
    };

    initialize();
  }, []);

  return (
    <DisclaimerContext.Provider
      value={{
        showDisclaimer: () => setOpen(true),
        showError: handleShowError,
      }}
    >
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            1. I undertake that I will comply with all laws, rules and regulations applicable to my use of the website and the underlying protocols. 
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            2. I do not reside in, are incorporated in, and do not have a registered office in the United States of America, the province of Ontario in Canada, the province of Québec in Canada, the Hong Kong Special Administrative Region of the People's Republic of China, the Republic of Seychelles, Bermuda, Burundi, Central African Republic, Democratic Republic of Congo, Eritrea, Guinea-Bissau, Libya, Mali, Palestine, Somalia, South Sudan, Western Sahara, Yemen, Cuba, Crimea and Sevastopol, Iran, Syria, North Korea or Sudan; (ii) any state, country or other jurisdiction that is embargoed by the United States of America; or (iii) any restricted territory, as defined in the Terms of Use and will not use a VPN or any other measures to circumvent restrictions on my use of the Site and underlying protocols.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            3. I understand that this website or underlying protocols are purely for educational purposes and by using it I assume all risks and agree to absolve the developers, contributors and any person associated with the protocol, directly or indirectly, from any legal or financial liability.
          </Typography>
          <div className="flex justify-center">
            <Button
              variant="contained"
              style={{
                backgroundColor: "#4A6D83",
                padding: "10px 20px",
                margin: "20px 9px",
                fontSize: "14px",
                textTransform: "uppercase",
              }}
              onClick={handleClose}
            >
              Agree & conitnue
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={showError}
        onClose={handleHideError}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="error-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            Error
          </Typography>
          <Typography
            id="error-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            {error}
          </Typography>
          <Typography
            id="error-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            {errorDetail}
          </Typography>
        </Box>
      </Modal>
    </DisclaimerContext.Provider>
  );
};
