import React, { Component } from "react";

export class Footer extends Component {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="w-full bg-primary flex justify-center p-5">
        <p className="text-headings font-body font-extrabold text-center">
          Copyright &copy; {this.getYear()}{" "}
          <a href="https://webixun.com" target="_blank" rel="noreferrer">
            Derived Finance
          </a>
          . <span>All Rights Reserved</span>  
        </p>
      </div>
    );
  }
}

export default Footer;
